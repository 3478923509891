<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :sub-name="$t('template')">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id :value="`${model.id}`" label="id" disabled />
        <n-field-enum v-model="model.level" enum-name="AdminNoticeLevel" label="level" :error="error.level" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum :value="model.type" enum-name="AdminNoticeType" label="type" disabled />
        <n-field-boolean v-model="model.active" label="active" :error="error.active" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum :value="model.domain" enum-name="AdminNoticeDomain" label="domain" disabled />
        <n-field-boolean v-model="model.popup" label="popup" :error="error.popup" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.version" label="version" disabled />
        <span>
          <n-field-enum v-model="model.sound" enum-name="AdminNoticeSound" label="sound" :error="error.sound" :disabled="disabled" />
          <Icon v-if="!disabled && model.sound !== $enums.AdminNoticeSound.SILENCE" custom="fa fa-volume-up" class="sound-play" @click.native="soundPlay" />
        </span>
      </n-row-col2>
      <n-row-col2>
        <ns-company :value="model.companyId" disabled />
        <n-field-enum v-model="model.target" enum-name="AdminNoticeTarget" label="target" :error="error.target" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <n-field-text v-model="model.subject" label="noticeSubject" :error="error.subject" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-show="model.target === $enums.AdminNoticeTarget.ALL" value="" label="noticeRecipient" disabled />
        <n-field-multiple-select v-show="model.target === $enums.AdminNoticeTarget.ROLE" v-model="roleRecipient" label="noticeRecipient" :options="roleOptions" :disabled="disabled" @on-change="changeRecipient" />
        <n-field-multiple-select v-show="model.target === $enums.AdminNoticeTarget.OPERATOR" v-model="operatorRecipient" label="noticeRecipient" :options="operatorOptions" :disabled="disabled" @on-change="changeRecipient" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { update, validate } from '@/api/operator/operator-notice-template';
import { getSelection } from '@/store';
import { isEmpty } from '@/helpers/utils';
import { operatorNoticeAudio } from '@/helpers/audio';

export default NModal.extend({
  name: 'ModalNoticeTemplate',
  components: { NModal },
  data() {
    return {
      roleRecipient: [],
      operatorRecipient: [],
    };
  },
  computed: {
    roleOptions() {
      let selections = getSelection('admin.role');
      return selections.filter(s => s.extra['$group'] === this.model.companyId);
    },
    operatorOptions() {
      let selections = getSelection('admin.operator');
      return selections.filter(s => s.extra['$group'] === this.model.companyId);
    },
  },
  beforeMount() {
    this.initRecipient();
  },
  methods: {
    initRecipient() {
      if (isEmpty(this.model.recipient)) {
        this.$set(this.model, 'recipient', '{}');
        this.roleRecipient = [];
        this.operatorRecipient = [];
      } else {
        let recipient = JSON.parse(this.model.recipient);
        this.roleRecipient = recipient.ROLE;
        this.operatorRecipient = recipient.OPERATOR;
      }
    },
    changeRecipient() {
      let roleOptions = isEmpty(this.roleRecipient) ? '' : this.roleRecipient.toString();
      let operatorOptions = isEmpty(this.operatorRecipient) ? '' : this.operatorRecipient.toString();
      if (isEmpty(roleOptions) && isEmpty(operatorOptions)) this.$set(this.model, 'recipient', '{}');
      else if (!isEmpty(roleOptions) && isEmpty(operatorOptions)) this.$set(this.model, 'recipient', `{"ROLE":[${roleOptions}]}`);
      else if (isEmpty(roleOptions) && !isEmpty(operatorOptions))
        this.$set(this.model, 'recipient', `{"OPERATOR":[${operatorOptions}]}`);
      else this.$set(this.model, 'recipient', `{"ROLE":[${roleOptions}],"OPERATOR":[${operatorOptions}]}`);
    },
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return update(model);
    },
    soundPlay() {
      operatorNoticeAudio.play(this.model.sound);
    },
  },
});
</script>

<style type="scss" scoped>
.sound-play {
  float: right;
  margin-top: -43px;
  position: relative;
  cursor: pointer;
  color: darkgray;
}

.sound-play:hover {
  color: #212529 !important;
}
</style>
