<template>
  <!-- prettier-ignore -->
  <index-content :breadcrumb-items="[$t(`menu.operator.notice_template`)]">
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th>{{ $t('label.id') }}</th>
            <th>{{ $t('label.level') }}</th>
            <th>{{ $t('label.company') }}</th>
            <th>{{ $t('label.domain') }}</th>
            <th>{{ $t('label.active') }}</th>
            <th>{{ $t('label.popup') }}</th>
            <th>{{ $t('label.sound') }}</th>
            <th>{{ $t('label.target') }}</th>
            <th class="type">{{ $t('label.type') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-enums:AdminNoticeLevel="row.level"></td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td v-enums:AdminNoticeDomain="row.domain"></td>
            <td>
              <n-icon-boolean :value="row.active" />
            </td>
            <td>
              <n-icon-boolean :value="row.popup" />
            </td>
            <td v-enums:AdminNoticeSound="row.sound"></td>
            <td v-enums:AdminNoticeTarget="row.target"></td>
            <td v-enums:AdminNoticeType="row.type"></td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import OperatorView from './view';
import ModalNoticeTemplate from './ModalNoticeTemplate';
import { fetch } from '@/api/operator/operator-notice-template';

export default OperatorView.extend({
  name: 'OperatorNoticeTemplate',
  data() {
    return {
      preload: true,
      condition: {
        companyId: null,
      },
    };
  },
  methods: {
    doLoad() {
      let params = { ...this.condition };
      return fetch(params);
    },
    parse(rows) {
      this.records = rows;
    },
    openModal(model = {}) {
      this.createModal(ModalNoticeTemplate, { model, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  .type {
    width: 13rem;
  }
}
</style>
