import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`operator/notice/templates`, params);
}

export function validate(model) {
  return $post(`operator/notice/template/validate`, model);
}

export function update(model) {
  return $post(`operator/notice/template/${model.id}`, model);
}
